<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl v-if="!isShowStatus" class="w152">
      <dt>구분</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="defaultStatus" />
      </dd>
    </dl>
    <dl>
      <dt>검색어</dt>
      <dd>
        <Input
          class="w380"
          type="text"
          placeholder="템플릿명을 입력하세요."
          :value.sync="keywordInputText"
          @onEnterKey="onClickSearch"
        />
        <!-- <div class="group_form">
          <Radio
            name="servicePartnerListRadio0"
            :dataList="keywordDatas"
            :selectedId.sync="keywordSelectedId"
          />
        </div> -->
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    SelectBox,
    Radio,
    Input,
  },
  mixins: [FilterBarMixin],
  props: {
    isShowStatus: Boolean,
    statusList: Array,
    defaultStatusValue: String,
    defaultInputCurrentText: String,
    keywordDatas: Array,
  },
  data() {
    return {
      defaultStatus: this.defaultStatusValue,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      obj.templateGubun = this.defaultStatus;

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.defaultStatus = this.defaultStatusValue;
      this.onClickResetFilterBar();
    },
  },
};
</script>
