<template>
  <ListRow>
    <td>{{ rowData.templateType }}</td>
    <td>{{ rowData.templateGubun }}</td>
    <td class="td_ellip" style="cursor: pointer">
      <a class="link_subject" @click="onClickOpenPopup">{{ rowData.templateName }}</a>
      <!--<router-link :to='toPath' class="link_subject" :data-ellip="rowData.templateName" v-html="rowData.templateName">
      </router-link>-->
    </td>
    <!-- <td>{{ rowData.regId }}</td> -->
    <td>{{ rowData.regDate | dateStringFormat }}</td>
    <td>
      <div class="group_form">
        <button type="button" class="btn_fourthly btn_small" @click="onClickEdit">수정</button>
        <button type="button" class="btn_fourthly btn_small" @click="onClickDelete">삭제</button>
      </div>
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "TemplateListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  // data() {
  //   return {
  //     isTemplatePopup: false
  //   }
  // },
  props: {
    rowData: Object,
  },
  computed: {
    toPath() {
      const { orderCid, status } = this.rowData;

      const isModify = status === "T";

      const routerPath = isModify ? this.$routerPath.ORDER_MODIFY : this.$routerPath.ORDER_VIEW;

      const path = makePathWithQuery(routerPath, orderCid, this.$route.query);

      return path;
    },
    stateType() {
      switch (this.rowData.status) {
        case "C":
          return "on";
        case "T":
          return "";
        case "E":
          return "disabled";
      }
      return "";
    },
  },
  methods: {
    onClickEdit() {
      this.$emit("onClickEditTemplate", this.rowData);
    },
    onClickDelete() {
      const params = {
        text: "템플릿을 삭제 하시겠습니까? ",
        onClickY: this.onClickDeleteOk,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickDeleteOk() {
      this.$emit("onClickDeleteTemplate", this.rowData);
    },
    onClickOpenPopup() {
      this.$emit("onClickOpenPopup", this.rowData);
    },
  },
};
</script>
