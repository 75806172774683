<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">템플릿 관리</h2>
      </div>
      <div class="body_section">
        <TemplateListFilterBar
          :defaultStatusValue="defaultStatus"
          :statusList="statusList"
          :keywordDatas="keywordDatas"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.SYSTEM_TEMPLATE_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickWrite"
            >
              <span class="ico_purchase ico_cross_w" />템플릿 등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 117px" />
            <!-- 템플릿 용도-->
            <col style="width: 180px" />
            <!-- 구분 -->
            <col style="width: 543px" />
            <!-- 템플릿명 -->
            <col style="width: 100px" />
            <!-- 등록자 -->
            <!-- <col style="width: 100px" /> -->
            <!-- 등록일 -->
            <col style="width: 200px" />
            <!-- 수정 삭제 -->
          </template>
          <template v-slot:tr>
            <th>템플릿 용도</th>
            <th>구분</th>
            <th>템플릿명</th>
            <!-- <th>등록자</th> -->
            <th>등록일</th>
            <th />
          </template>
          <template v-slot:row="slotProps">
            <TemplateListLine
              :rowData="slotProps.rowData"
              @onClickEditTemplate="onClickEditTemplate"
              @onClickDeleteTemplate="onClickDeleteTemplate"
              @onClickOpenPopup="onClickOpenPopup"
            />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="5" class="td_empty">템플릿이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <TemplatePreviewPopup
        v-if="isTemplatePopup"
        :templateId="templateId"
        :isTemplateManage="isTemplateManage"
        @onClickClose="onClickClose"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import ListPageMixin from "@/mixins/ListPageMixin";
import PageMixin from "@/mixins/PageMixin";

import { mapState } from "vuex";

import TemplateListFilterBar from "@/components/admin/system/template/list/TemplateListFilterBar";
import TemplateListLine from "@/components/admin/system/template/list/TemplateListLine";
import TemplatePreviewPopup from "@/components/shared/template/TemplatePreviewPopup.vue";

import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import ApiService from "@/services/ApiService";
import { ORDER_LIST_ACTION } from "@/store/modules/order/action";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { GET_TEMPLATE_GUBUN_LIST } from "@/store/modules/template/action";

export default {
  name: "TemplateList",
  components: {
    PageWithLayout,
    ListPage,
    TemplateListFilterBar,
    TemplateListLine,
    TableHead,
    SelectBoxPageRange,
    TemplatePreviewPopup,
  },
  mixins: [ListPageMixin, PageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.TEMPLATE,

      defaultStatus: "",
      defaultDates: [],
      // statusList:[
      //   { code: '0', name:'전체'},
      //   { code: 'C', name:'기본 탬플릿'},
      //   { code: 'D', name:'별도 탬플릿'},
      // ],
      keywordDatas: [
        { id: "title", text: "템플릿 명" },
        { id: "id", text: "서식 ID" },
      ],
      defaultKeywordSelectedId: "title",

      defaultInputCurrentText: "",
      isTemplatePopup: false,
      isTemplateManage: "Y",
      templateId: null,
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_TEMPLATE_GUBUN_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  mounted() {
    // this.$store.dispatch( ORDER_LIST_ACTION ).then( () => {
    //   this.$refs.listPage.getData();
    // } );
  },
  computed: {
    ...mapState({
      statusList: (state) => state.template.statusList,
    }),
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordDatas);

      const objGubun = {};

      if (query.templateGubun) {
        objGubun.templateGubun = query.templateGubun;
      }

      const objText = {};

      if (query.templateName) {
        objText.templateName = query.templateName;
      }

      if (query.templateId) {
        objText.templateId = query.templateId;
      }

      return Object.assign({}, objPaging, objKeyword, objGubun, objText);
    },
    // makeSearchParamsByFilterBar(objData) {
    //   const { keywordSelectBoxId, inputCurrentText, selectBoxStatusCurrentValue } = objData;
    //
    //   let params = {};
    //
    //   if (dates.length === 2) {
    //     params.orderDate1 = dateToString(dates[0]);
    //     params.orderDate2 = dateToString(dates[1]);
    //   }
    //
    //   if (!!keywordSelectBoxId && !!inputCurrentText) {
    //     params[keywordSelectBoxId] = inputCurrentText;
    //   }
    //
    //   const cateNum = category2 || category1 || category0;
    //
    //   if (cateNum) {
    //     params.cateNum = cateNum;
    //   }
    //
    //   params.status = objData.selectBoxStatusCurrentValue;
    //
    //   return params;
    // },
    onClickWrite() {
      // this.$router.push({ path: this.$routerPath.TEMPLATE_WRITE });

      this.$router.push({
        path: this.$routerPath.SYSTEM_TEMPLATE_WRITE,
        params: {
          fromTemplateManage: true,
        },
      });
    },
    onClickEditTemplate(obj) {
      this.$router.push({
        name: `${this.$routerPath.SYSTEM_TEMPLATE_MODIFY}`,
        params: {
          templateId: obj.templateId,
          beforePage: "TemplateList",
        },
      });
      //this.updateTemplate(obj);
    },
    onClickDeleteTemplate(obj) {
      this.deleteTemplate(obj);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, templateGubun } = objData;
      let params = {};

      // if( keywordSelectedId == 'title' && keywordInputText != undefined ) {params.templateName = keywordInputText;}
      // if( keywordSelectedId == 'id' && keywordInputText != undefined) {params.templateId = keywordInputText;}
      if (keywordSelectedId == "title") {
        params.templateName = keywordInputText;
      } else {
        params.templateId = keywordInputText;
      }
      if (templateGubun) {
        params.templateGubun = templateGubun;
      }

      return params;
    },
    async deleteTemplate(objData) {
      const path = `${this.$apiPath.TEMPLATE_DELETE}?templateId=${objData.templateId}`;

      const result = await ApiService.shared.postData(path);

      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }

      this.alert("템플릿 삭제가 완료되었습니다.");
      this.refresh();
      //this.goToList();
    },
    // async updateTemplate(){

    //   const url = `${this.$apiPath.TEMPLATE}/update`;

    //   let templateUpdate = {};
    //   templateUpdate.templateId     = data.result.template_id;
    //   templateUpdate.templateType   = data.type;
    //   // 이 값은 추후에 수정하기.
    //   templateUpdate.templateGubun  = '';

    //   let obj = {};
    //   obj.templateUpdate = templateUpdate;

    //   const result = await ApiService.shared.putData(url, obj);

    //   if (result.code !== "200") {
    //     this.alert(result.text);

    //     this.resetStatus();
    //     return;
    //   }

    // },
    goToList() {
      this.$router.push({
        path: this.$routerPath.TEMPLATE_LIST,
        //query: this.$route.query
      });
    },
    refresh() {
      const params = this.checkUrlQuery();
      this.getDataInit(params);
    },
    onClickOpenPopup(obj) {
      this.templateId = obj.templateId;
      this.status = "C";
      this.isTemplatePopup = true;
    },
    onClickClose() {
      this.isTemplatePopup = false;
    },
  },
};
</script>
